import React from 'react';
import PageLayout from '../../Layout/Page';
import { useStaticQuery, graphql } from 'gatsby';
import FacultyMemberCard from '../Card';
import usePreviewData from '../../../utils/usePreviewData';

const FacultyMemberPage = ({ pageContext }) => {
  const { allPrismicFacultyMember } = useStaticQuery(graphql`
    query {
      allPrismicFacultyMember {
        nodes {
          id
          prismicId
          data {
            name
            position
            portrait {
              fluid(maxWidth: 320) {
                ...GatsbyPrismicImageFluid
              }
            }
            bio {
              html
              text
            }
          }
        }
      }
    }
  `);
  const thisFacultyMember = allPrismicFacultyMember?.nodes?.find(
    node => node.prismicId === pageContext.data.prismicId,
  );
  const { facultyMember } = usePreviewData({
    facultyMember: thisFacultyMember,
  });
  return (
    <PageLayout
      title={facultyMember?.data?.name}
      meta_description={facultyMember?.data?.bio?.text}
    >
      <FacultyMemberCard
        {...{
          ...facultyMember.data,
          imageFluid: facultyMember.data.portrait?.fluid,
          bio: facultyMember.data.bio?.html,
        }}
      />
    </PageLayout>
  );
};

export default FacultyMemberPage;
