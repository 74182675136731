import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import styled, { css } from 'styled-components';
import { space, display } from 'styled-system';
import Image from 'gatsby-image';
import sanitize from 'sanitize-html';
import Card from '../../Card';
import Text from '../../Text';
import Hr from '../../Layout/Hr';

const Avatar = styled(Box)`
  border-radius: 50%;
  width: 96px;
  height: 96px;
  overflow: hidden;
  background: red;
`;

const BoxWithDisplay = styled(Box)`
  ${display}
`;

const Bio = styled.div`
  ${space}
  ${({ theme }) =>
    css`
      color: ${theme.color.secondary};
    `}
`;

const FacultyMemberCard = ({ name, position, bio, imageFluid }) => {
  const htmlBio = sanitize(bio);
  return (
    <Card p={4}>
      <Flex m={-2} alignItems={['center', null, 'flex-start']}>
        <Box p={2}>
          <Avatar>
            <Image
              objectFit="cover"
              style={{ height: 96 }}
              objectPosition="center center"
              fluid={imageFluid}
            />
          </Avatar>
        </Box>
        <Box p={2} flex="1">
          <Flex justifyContent="space-between">
            <Flex flexDirection="column" justifyContent="center" p={2}>
              <Text color="valhalla" fontSize={[4, 5]} as="h3">
                {name}
              </Text>
            </Flex>
          </Flex>
          <BoxWithDisplay display={['none', null, null, 'block']} p={2}>
            <Bio
              key="bio-right"
              dangerouslySetInnerHTML={{ __html: htmlBio }}
            />
            <Hr width={1} my={3} height="1px" />
            <Text color="chestnutRose">{position}</Text>
          </BoxWithDisplay>
        </Box>
      </Flex>
      <BoxWithDisplay mt={3} display={['block', null, null, 'none']} p={2}>
        <Bio key="bio-bottom" dangerouslySetInnerHTML={{ __html: htmlBio }} />
        <Hr width={1} my={3} height="1px" />
        <Text color="chestnutRose">{position}</Text>
      </BoxWithDisplay>
    </Card>
  );
};

FacultyMemberCard.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  bio: PropTypes.string,
  imageFluid: PropTypes.object,
};

export default FacultyMemberCard;
